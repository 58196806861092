import React from "react";
import styled from "styled-components";

import { SpacingScales } from "../Spacings/helpers";

type SwitcherLayoutProps = {
  threshold: string;
  scale: SpacingScales;
};

const SwitcherLayout = styled.div<SwitcherLayoutProps>`
  /* Inspired by the Switcher component from Every Layout
  https://absolutely.every-layout.dev/layouts/switcher/ */
  > * {
    display: flex;
    flex-wrap: wrap;
    margin: calc((${(props) => props.theme.spacings[props.scale]} / 2) * -1);
  }

  > * > * {
    flex-grow: 1;
    flex-basis: calc(
      (
          ${(props) => props.threshold} -
            (100% - ${(props) => props.theme.spacings[props.scale]})
        ) * 999
    );
    margin: calc(${(props) => props.theme.spacings[props.scale]} / 2);
    /* Prevent the width from exploding in IE11 */
    max-width: 100%;
  }
`;

const Switcher: React.FC<
  Omit<JSX.IntrinsicElements["div"], "ref"> &
    Omit<SwitcherLayoutProps, "scale"> & {
      scale?: SpacingScales;
    } & { as?: keyof JSX.IntrinsicElements }
> = ({ scale, ...rest }) => (
  <SwitcherLayout {...rest} scale={scale || "small"}>
    {/*
      intermediary div in order to avoid the negativ margin influencing
      adjacent components
    */}
    <div>{rest.children}</div>
  </SwitcherLayout>
);

export default Switcher;
