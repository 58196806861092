import transparentize from "polished/lib/color/transparentize";
import React, { createContext, useContext } from "react";
import styled, { css } from "styled-components";
import logger from "../../utils/logger";

import CollapsibleStateContainer from "../CollapsibleStateContainer";
import ChevronTopIcon from "../Icons/ChevronTopIcon";
import { IconPrority, Scale } from "../Icons/useMappedProps";
import { InsetSquishProps } from "../InsetSquish";
import Spacings from "../Spacings";
import { SpacingScales } from "../Spacings/helpers";

type CollapsibleContextProperties = {
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
};

// We have to pass a default value here to make TypeScript happy. The value
// doesn't matter in the end because it will be set when we render the
// provider in the `Collapsible` component.
const CollapsibleContext = createContext<CollapsibleContextProperties>({
  isOpen: false,
  onToggle: () => {
    /* dummy function */
  },
});

export const StyledHeader = styled.button<{
  isOpen?: boolean;
  insetSquishScale?: InsetSquishProps["scale"];
}>`
  all: inherit;
  border: 0;
  width: 100%;
  cursor: pointer;
  text-align: left;
  margin: 0;
  padding: ${(props) =>
    !props.insetSquishScale
      ? 0
      : `${props.theme.insetSquish.spacings[props.insetSquishScale!]}px
    ${
      props.theme.insetSquish.spacings[props.insetSquishScale!] *
      props.theme.insetSquish.squishRatio
    }px`};
  background-color: transparent;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px
      ${(props) => transparentize(0.7, props.theme.palette.brand.main)};
  }
`;

type ControlledProps = {
  isDefaultOpened?: undefined;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
  children?: React.ReactNode;
};

type UncontrolledProps = {
  isDefaultOpened?: boolean;
  isOpen?: undefined;
  onToggle?: (isOpen: boolean) => void;
  children?: React.ReactNode;
};

const StyledCollapsibleHeadline = styled.div`
  flex-grow: 1;
`;

const StyledChevronTopIcon = styled(ChevronTopIcon)<{
  $isOpen: boolean;
  isOnDarkBackground?: boolean;
}>`
  transform: rotate(${(props) => (props.$isOpen ? "0" : "180deg")});
  transition: transform 200ms;
  ${(props) =>
    props.isOnDarkBackground &&
    css`
      color: ${props.theme.palette.white};
    `};
`;

const filterChildren = (
  children: React.ReactNode,
  componentName: string,
  allowedComponentNames: string[],
) =>
  React.Children.toArray(children).filter((child: any) => {
    if (
      !child ||
      !child.type ||
      !allowedComponentNames.includes(child.type.displayName)
    ) {
      logger({
        message: `You tried to render something as child of a "${componentName}" component which is neither a ${allowedComponentNames
          .map((name) => `"${name}"`)
          .join(" nor a ")} component. This child is being ignored!`,
        status: "warn",
      });
      return false;
    }
    return true;
  }) as React.ReactElement[];

const CollapsibleHeadlineDisplayName = "Collapsible.Headline";
const CollapsibleHeadline: React.FC<{ children?: React.ReactNode }> = (
  props,
) => <StyledCollapsibleHeadline>{props.children}</StyledCollapsibleHeadline>;
CollapsibleHeadline.displayName = CollapsibleHeadlineDisplayName;

const CollapsibleChevronDisplayName = "Collapsible.Chevron";
const CollapsibleChevron: React.FC<{
  isOnDarkBackground?: boolean;
  priority?: IconPrority;
  scale?: Scale;
}> = (props) => {
  const collapsibleContext = useContext(CollapsibleContext);
  return (
    <StyledChevronTopIcon
      scale={props.scale}
      aria-hidden="true"
      priority={props.priority}
      $isOpen={collapsibleContext.isOpen}
      isOnDarkBackground={props.isOnDarkBackground}
    />
  );
};
CollapsibleChevron.displayName = CollapsibleChevronDisplayName;

const CollapsibleHeaderDisplayName = "Collapsible.Header";
const CollapsibleHeader: React.FC<{
  className?: string;
  inlineScale?: SpacingScales;
  insetSquishScale?: InsetSquishProps["scale"];
  children?: React.ReactNode;
}> = (props) => {
  const collapsibleContext = useContext(CollapsibleContext);
  const filteredChildren = filterChildren(
    props.children,
    CollapsibleHeaderDisplayName,
    [CollapsibleHeadlineDisplayName, CollapsibleChevronDisplayName],
  ).reduce((acc, child: any) => {
    const childType = child.type.displayName;
    const existingType = acc.find(
      (element) => (element as any).type.displayName === childType,
    );
    if (existingType) {
      logger({
        message: `You tried to render multiple "${childType}" components inside a "${CollapsibleHeaderDisplayName}". Only the first of those children is considered, the rest was ignored.`,
        status: "warn",
      });
      return acc;
    }
    acc.push(child);
    return acc;
  }, [] as React.ReactElement[]);
  return (
    <StyledHeader
      type="button"
      isOpen={collapsibleContext.isOpen}
      aria-expanded={collapsibleContext.isOpen}
      onClick={() => {
        collapsibleContext.onToggle(!collapsibleContext.isOpen);
      }}
      className={props.className}
      insetSquishScale={props.insetSquishScale}
    >
      <Spacings.Inline alignItems="center" scale={props.inlineScale}>
        {filteredChildren}
      </Spacings.Inline>
    </StyledHeader>
  );
};
CollapsibleHeader.displayName = CollapsibleHeaderDisplayName;

const CollapsibleContentDisplayName = "Collapsible.Content";
const CollapsibleContent: React.FC<{
  className?: string;
  children?: React.ReactNode;
}> = (props) => {
  const collapsibleContext = useContext(CollapsibleContext);
  if (!collapsibleContext.isOpen) {
    return null;
  }
  return <div className={props.className}>{props.children}</div>;
};
CollapsibleContent.displayName = CollapsibleContentDisplayName;

type CollapsibleProps = ControlledProps | UncontrolledProps;

const CollapsibleDisplayName = "Collapsible";
const Collapsible: React.FC<CollapsibleProps> & {
  Header: typeof CollapsibleHeader;
  Headline: typeof CollapsibleHeadline;
  Chevron: typeof CollapsibleChevron;
  Content: typeof CollapsibleContent;
} = ({ children, ...stateContainerProps }) => (
  <CollapsibleStateContainer {...stateContainerProps}>
    {({ isOpen, onToggle }) => {
      const filteredChildren = filterChildren(
        children,
        CollapsibleDisplayName,
        [CollapsibleHeaderDisplayName, CollapsibleContentDisplayName],
      ).reduce((acc, child: any) => {
        const childType = child.type.displayName;
        const existingType = acc.find(
          (element) => (element as any).type.displayName === childType,
        );
        if (existingType) {
          logger({
            message: `You tried to render multiple "${childType}" components inside a "Collapsible". Only the first of those children is considered, the rest was ignored.`,
            status: "warn",
          });
          return acc;
        }
        acc.push(child);
        return acc;
      }, [] as React.ReactElement[]);
      if (
        !filteredChildren.find(
          (child: any) =>
            child.type.displayName === CollapsibleHeaderDisplayName,
        )
      ) {
        throw new Error(
          `You tried to render a "Collapsible" without a "${CollapsibleHeaderDisplayName}" as child. This is not allowed.`,
        );
      }
      if (
        !filteredChildren.find(
          (child: any) =>
            child.type.displayName === CollapsibleContentDisplayName,
        )
      ) {
        throw new Error(
          `You tried to render a "Collapsible" without a "${CollapsibleContentDisplayName}" as child. This is not allowed.`,
        );
      }
      return (
        <CollapsibleContext.Provider value={{ isOpen, onToggle }}>
          <div>{filteredChildren}</div>
        </CollapsibleContext.Provider>
      );
    }}
  </CollapsibleStateContainer>
);
Collapsible.displayName = CollapsibleDisplayName;

Collapsible.Header = CollapsibleHeader;
Collapsible.Headline = CollapsibleHeadline;
Collapsible.Chevron = CollapsibleChevron;
Collapsible.Content = CollapsibleContent;

export default Collapsible;
