import React from "react";
import styled from "styled-components";

import AccountIcon from "../Icons/AccountIcon";

export type AccountLinkProps = {
  href: string;
  label: string;
  hideLabel?: boolean;
};

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;

  > svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: ${(props) => props.theme.spacings.small};
  }
`;

const StyledLabel = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

const AccountLink: React.FC<AccountLinkProps> = ({
  hideLabel,
  href,
  label,
}) => (
  <StyledLink aria-label={label} href={href}>
    <AccountIcon color="brand" scale={hideLabel ? "big" : "small"} />
    {!hideLabel ? <StyledLabel>{label}</StyledLabel> : null}
  </StyledLink>
);

export default AccountLink;
