import React from "react";
import styled from "styled-components";

import Spacings from "../Spacings";
import Breadcrumb from "./Breadcrumb";
import { HomeIcon, ChevronRightIcon } from "../Icons";

const DEFAULT_DIVIDER = <ChevronRightIcon color="currentColor" scale="small" />;

type Crumb = {
  label: string;
  slug?: string;
  dividerIcon?: React.ReactNode;
};

type BreadcrumbsProps = {
  crumbs: Crumb[];
};

const StyledContainer = styled(Spacings.Inline).attrs({ as: "ol" })`
  svg {
    margin-left: 1rem;
  }

  & li {
    flex-shrink: 0;

    &:last-child {
      flex-shrink: 1;
      padding-right: 2rem;
    }
  }

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    & li svg:first-child {
      margin-left: 0;
    }
  }
`;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs }) => (
  <StyledContainer
    itemScope
    scale="medium"
    alignItems="flexStart"
    itemType="https://schema.org/BreadcrumbList"
  >
    {crumbs.map(({ label, slug, dividerIcon = DEFAULT_DIVIDER }, index) => (
      <Breadcrumb
        key={index}
        label={label}
        slug={index < crumbs.length - 1 ? slug : undefined}
        replaceTextOnMobileWith={
          index === 0 && <HomeIcon scale="small" color="currentColor" />
        }
      >
        <meta itemProp="position" content={(index + 1).toString()} />

        {index < crumbs.length - 1 && dividerIcon}
      </Breadcrumb>
    ))}
  </StyledContainer>
);

export default Breadcrumbs;
