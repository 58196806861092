import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled from "styled-components";

import Center from "../Center";
import { Theme } from "../designTokens";
import VisuallyHidden from "../VisuallyHidden";

type Variant = "brand" | "accent" | "white";

const getColorByVariant = (variant: Variant, theme: Theme) => {
  switch (variant) {
    case "brand":
      return theme.palette.brand.main;
    case "accent":
      return theme.palette.accent.main;
    case "white":
    default:
      return theme.palette.white;
  }
};

type Scale = "big" | "small";

const getSizeByScale = (scale: Scale = "small") => {
  switch (scale) {
    case "big":
      return 30;
    case "small":
    default:
      return 10;
  }
};

type ThreeDotLoaderProps = {
  isActive: boolean;
  variant?: Variant;
  scale?: Scale;
};

const ThreeDotLoader = styled.div.attrs((props: ThreeDotLoaderProps) => ({
  size: getSizeByScale(props.scale),
  variant: props.variant || "brand",
}))<ThreeDotLoaderProps>`
  position: relative;
  background-color: ${(props) => getColorByVariant(props.variant, props.theme)};
  border-radius: ${(props) => props.size / 2}px;
  color: ${(props) => getColorByVariant(props.variant, props.theme)};
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  animation: ${(props) => `dot-flashing-${props.variant}`} 1s 0.5s infinite
    alternate;
  opacity: ${(props) => (props.isActive ? 0.8 : 0)};
  transition: opacity 0.3s;
  &::before,
  &::after {
    background-color: ${(props) =>
      getColorByVariant(props.variant, props.theme)};
    border-radius: ${(props) => props.size / 2}px;
    color: ${(props) => getColorByVariant(props.variant, props.theme)};
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    animation: ${(props) => `dot-flashing-${props.variant}`} 1s infinite
      alternate;
    left: -${(props) => props.size + props.size / 4}px;
  }
  &::after {
    animation: ${(props) => `dot-flashing-${props.variant}`} 1s 1s infinite
      alternate;
    left: ${(props) => props.size + props.size / 4}px;
  }

  @keyframes dot-flashing-brand {
    from {
      background-color: ${(props) => getColorByVariant("brand", props.theme)};
    }

    to {
      background-color: ${(props) =>
        transparentize(0.8)(getColorByVariant("brand", props.theme))};
    }
  }
  @keyframes dot-flashing-accent {
    from {
      background-color: ${(props) => getColorByVariant("accent", props.theme)};
    }

    to {
      background-color: ${(props) =>
        transparentize(0.8)(getColorByVariant("accent", props.theme))};
    }
  }
  @keyframes dot-flashing-white {
    from {
      background-color: ${(props) => getColorByVariant("white", props.theme)};
    }

    to {
      background-color: ${(props) =>
        transparentize(0.8)(getColorByVariant("white", props.theme))};
    }
  }
`;

type AccessibleThreeDotLoaderProps = ThreeDotLoaderProps & {
  className?: string;
  loadingText?: React.ReactNode;
};

const AccessibleThreeDotLoader: React.FC<AccessibleThreeDotLoaderProps> & {
  Centered: React.JSXElementConstructor<AccessibleThreeDotLoaderProps>;
} = ({ className, scale, variant, isActive, loadingText }) => (
  <>
    {isActive && (
      <ThreeDotLoader
        className={className}
        scale={scale}
        variant={variant}
        isActive={isActive}
        data-testid="three-dot-loader"
      />
    )}
    {isActive && loadingText && (
      <VisuallyHidden aria-live="assertive" role="alert">
        {loadingText}
      </VisuallyHidden>
    )}
  </>
);

const Centered = styled(Center).attrs({
  intrinsic: true,
})`
  min-height: 256px;
  justify-content: center;
`;

AccessibleThreeDotLoader.Centered = ({ scale = "big", ...props }) => (
  <Centered>
    <AccessibleThreeDotLoader {...props} scale={scale} />
  </Centered>
);

export default AccessibleThreeDotLoader;
