import React from "react";
import styled from "styled-components";

import { StyledAlertMessage } from "../Alert";
import Spacings from "../Spacings";
import Text from "../Text";

const StyledErrorMessage = styled.div`
  border: ${(props) => props.theme.borders.width.big}px solid
    ${(props) => props.theme.palette.error.main};
  border-radius: ${(props) => props.theme.borders.radius.small}px;
  ${/* sc-selector */ StyledAlertMessage}:focus > & {
    box-shadow: 0 0 0 3px ${(props) => props.theme.palette.error.light};
  }
`;

type ErrorSummaryProps = {
  title: React.ReactNode;
  usePxUnits?: boolean;
  children?: React.ReactNode;
};

const ErrorSummary: React.FC<ErrorSummaryProps> = (props) => (
  <StyledErrorMessage>
    <Spacings.Inset scale="medium" usePxUnits={props.usePxUnits}>
      <Spacings.Stack usePxUnits={props.usePxUnits}>
        <Text textStyle="headline5" usePxFontSize={props.usePxUnits}>
          {props.title}
        </Text>
        <Spacings.Stack usePxUnits={props.usePxUnits}>
          {props.children}
        </Spacings.Stack>
      </Spacings.Stack>
    </Spacings.Inset>
  </StyledErrorMessage>
);

export default ErrorSummary;
