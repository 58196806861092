import React from "react";
import styled from "styled-components";

import Text, { TextProps } from "../Text";

type FormErrorProps = TextProps &
  React.PropsWithoutRef<JSX.IntrinsicElements["p"]>;

const FormError: React.FC<FormErrorProps> = (props) => (
  <Text priority="error" textStyle="caption" {...props} />
);

const StyledFormError = styled(FormError)``;

export default StyledFormError;
