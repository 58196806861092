import React from "react";
import styled, { css } from "styled-components";

import Spacings from "../Spacings";
import { SpacingScales } from "../Spacings/helpers";
import { mapBadgeColorToTheme, mapWrapColorToTheme } from "./helpers";

type Outset = NonNullable<SpacingScales>;
export type BadgeColor = "brand" | "accent" | "mint" | "gray";

const clipPathShift = "10px";
const clipPath = `polygon(100% 0%, calc(100% - ${clipPathShift}) 50%, 100% 100%, 0 100%, 0 0)`;
const clipPathInner = `polygon(100% -2px, calc(100% - ${clipPathShift}) 50%, calc(100% - 1px) 100%, 0 100%, 0 0)`;

const StyledTitleBadgeWrapper = styled.div<{
  outset: Outset;
  shouldWrap: boolean;
}>`
  ${(props) =>
    props.shouldWrap &&
    css`
      position: relative;
      z-index: 1;
    `}

  ${(props) =>
    props.outset &&
    props.shouldWrap &&
    css`
      transform: translateX(calc(-${props.theme.spacings[props.outset]} + 1px))
        translateY(-${props.theme.spacings[props.outset]});
    `}
`;

const StyledTitleBadge = styled.div<{
  color: BadgeColor;
}>`
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: ${(props) =>
    mapBadgeColorToTheme(props.color, props.theme)};
  padding: ${(props) => props.theme.borders.width.medium}px;

  @supports (clip-path: ${clipPath}) {
    clip-path: ${clipPath};
  }
`;

const StyledTitleBadgeInner = styled(Spacings.Inset)<{
  outline: boolean;
}>`
  padding: 6px;
  @supports (clip-path: ${clipPathInner}) {
    clip-path: ${clipPathInner};
    padding-right: calc(${clipPathShift} + 6px);
  }
  ${(props) =>
    props.outline &&
    css`
      background-color: white;
    `}
`;

const TriangleBackground = styled.div<{ outset: Outset; color: BadgeColor }>`
  position: absolute;
  bottom: -${(props) => props.theme.spacings[props.outset]};
  left: 0;
  border-style: solid;
  border-width: 0 calc(${(props) => props.theme.spacings[props.outset]} - 1px)
    ${(props) => props.theme.spacings[props.outset]} 0;
  border-color: transparent
    ${(props) => mapWrapColorToTheme(props.color, props.theme)} transparent
    transparent;
`;

const Badge: React.FC<{
  outset?: Outset;
  shouldWrap?: boolean;
  color?: BadgeColor;
  outline?: boolean;
  children?: React.ReactNode;
}> = ({
  outset = "small",
  shouldWrap = true,
  color = "brand",
  outline = false,
  children,
}) => (
  <StyledTitleBadgeWrapper outset={outset} shouldWrap={shouldWrap}>
    {shouldWrap && <TriangleBackground outset={outset} color={color} />}
    <StyledTitleBadge color={color}>
      <StyledTitleBadgeInner outline={outline}>
        {children}
      </StyledTitleBadgeInner>
    </StyledTitleBadge>
  </StyledTitleBadgeWrapper>
);

export default Badge;
