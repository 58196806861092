import React, { useId } from "react";
import styled from "styled-components";

import { getSizeByScale, Scale } from "../Icons/useMappedProps";
import Spacings from "../Spacings";

const Container = styled.svg<{ gradientId: string }>`
  > svg {
    color: ${(props) => props.theme.palette.accent.light};
    /* stylelint-disable-next-line color-no-invalid-hex */
    fill: url(${(props) => `#${props.gradientId}`});
  }
`;

const StyledStop = styled.stop<{ isFilled?: boolean }>`
  stop-color: ${(props) =>
    props.isFilled ? props.theme.palette.accent.light : "none"};
  stop-opacity: ${(props) => (props.isFilled ? 1 : 0)};
`;

export type SingleRatingProps = {
  icon: React.ComponentType<{ height: string; width: string }>;
  id: string;
  scale?: Scale;
  score: number;
};

export const SingleRating: React.FC<SingleRatingProps> = (props) => {
  const offset = `${props.score}%`;
  const size = getSizeByScale(props.scale).toString();
  const gradientId = `ratingGradient${props.id}`;

  return (
    <Container height={size} width={size} gradientId={gradientId}>
      <defs>
        <linearGradient id={gradientId}>
          <StyledStop offset={offset} isFilled />
          <StyledStop offset={offset} />
        </linearGradient>
      </defs>
      <props.icon height={size} width={size} />
    </Container>
  );
};

export type RatingProps = {
  icon: React.ComponentType<{ height: string; width: string }>;
  numberOfIcons?: number;
  iconScale?: Scale;
  spacingScale?: Scale;
  score: number;
};

const Rating: React.FC<RatingProps> = ({
  icon,
  numberOfIcons = 5,
  iconScale,
  spacingScale = iconScale,
  score,
}) => {
  const id = useId();
  return (
    <Spacings.Inline scale={spacingScale}>
      {Array(numberOfIcons)
        .fill(0)
        .map((_, index) => (
          <SingleRating
            icon={icon}
            id={id}
            key={index}
            scale={iconScale}
            score={Math.max(
              Math.min(score * numberOfIcons - index * 100, 100),
              0,
            )}
          />
        ))}
    </Spacings.Inline>
  );
};

export default Rating;
