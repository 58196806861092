import React from "react";
import styled, { css } from "styled-components";

import Checkbox from "../Checkbox";
import Text, { lineHeightByTextStyle, TextStyles } from "../Text";

export const CHECKED_CLASS_NAME = "checked";

export const StyledLabel = styled(Text).attrs({
  as: "label",
  isUncropped: true,
})`
  display: flex;
`;

type StyledCheckboxProps = {
  textStyle: NonNullable<TextStyles>;
  invalid?: boolean;
};

export const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  margin-right: ${(props) => props.theme.spacings.small};

  /* Makes the radio button the same size as the line-height  */
  font-size: ${(props) =>
    lineHeightByTextStyle(props.theme)[props.textStyle]}em;
  flex-shrink: 0;

  ${(props) =>
    props.invalid &&
    css`
      color: ${props.theme.palette.error.main};
    `}
`;

export const LabelText = styled.span`
  flex-grow: 1;
`;

type CheckboxProps = React.ComponentProps<typeof Checkbox>;

type LabelledCheckboxProps = CheckboxProps & {
  label: React.ReactNode;
  textStyle?: TextStyles;
  invalid?: boolean;
};

const LabelledCheckbox = React.forwardRef<
  HTMLInputElement,
  LabelledCheckboxProps
>(
  (
    {
      label,
      textStyle = "body",
      className = "",
      checked,
      invalid,
      ...checkboxProps
    },
    ref,
  ) => (
    <StyledLabel
      textStyle={textStyle}
      className={`${className} ${checked ? CHECKED_CLASS_NAME : ""}`}
    >
      <StyledCheckbox
        {...checkboxProps}
        textStyle={textStyle}
        checked={checked}
        ref={ref}
        invalid={invalid}
      />
      <LabelText>{label}</LabelText>
    </StyledLabel>
  ),
);

export default LabelledCheckbox;
